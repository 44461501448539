import React from "react";
import { Header, AppWrapper, Button } from "../components";
import facebook from "../data/facebook.bmp";
import twitter from "../data/twitter.bmp";
import insta from "../data/instagram.png";
import image from "../data/1.jpg";

export default function Home() {
  return (
    <>
      <AppWrapper>
        <Header />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              color: "white",
              fontSize: "20px",
              padding: "10px 40px",
              lineHeight: "34px",
            }}
          >
            <p>
              Solent Model Construction offers a full kit building service aimed at the private
              collector & museums.
            </p>
            <p>
              With over 30 years experience in all forms of modelling from basic kit making through
              scratch building to the latest weathering techniques we can offer a top quality
              service.
            </p>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <img
                src={image}
                alt="ships"
                style={{ width: "80%", maxWidth: "500px", maxHeight: "200px" }}
              />
            </div>
            <p>
              So if you want a model of the plane you flew, the tank you drove, the ship you served
              on or a figure of the regiment you marched with we can help you.
            </p>
            <ul style={{ padding: "0px 40px" }}>
              <li>Full kit making service</li>
              <li>Figures</li>
              <li>Military ships</li>
              <li>Aircraft</li>
              <li>Cars, trucks and motorcycles</li>
              <li>Sci Fi</li>
              <li>Dioramas</li>
            </ul>
          </div>
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Button>Gallery</Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexGrow: "1",
            flexDirection: "row",
            alignItems: "center",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0px 20px",
              fontSize: "18px",
            }}
          >
            <div style={{ padding: "10px 0px 10px 10px" }}>Tel: 07834 538096</div>
            <div style={{ padding: "0px 10px 5px 10px" }}>
              Email: info@solentmodelconstruction.co.uk
            </div>
          </div>
        </div>
        <div style={{ width: "100%", display: "flex", padding: "10px 0px" }}>
          <img
            src={facebook}
            alt="facebook logo"
            style={{ width: "40px", height: "40px", padding: "0px 20px" }}
          />
          <img
            src={twitter}
            alt="twitter logo"
            style={{ width: "40px", height: "40px", padding: "0px 20px" }}
          />
          <img
            src={insta}
            alt="instagram logo"
            style={{ width: "40px", height: "40px", padding: "0px 20px" }}
          />
        </div>
      </AppWrapper>
    </>
  );
}
