import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

function AppWrapper({ children }) {
  return (
    <Scrollbars
      style={{
        height: "100%",
        width: "100%",
        fontFamily: "Roboto, sans-serif",
        overflow: "hidden",
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "black",
      }}
    >
      {children}
    </Scrollbars>
  );
}
export default AppWrapper;
