import React from "react";
import logo from "../data/solentLogo.jpg";

export default function Header() {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={logo}
        alt="solent model construction logo"
        style={{ width: "100%", maxWidth: "500px", maxHeight: "150px" }}
      />
    </div>
  );
}
