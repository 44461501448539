import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import { makeStyles } from "@material-ui/core/styles";

export default function Button({ children, onClick, disabled }) {
  const useStyles = makeStyles(() => ({
    primary: {
      backgroundColor: "#efefef",
      padding: "10px",
      width: "300px",
      fontSize: "20px",
      fontWeight: "600",
      borderRadius: "10px",
      border: "1px solid blue",
    },
  }));
  const classes = useStyles();
  return (
    <ButtonBase onClick={onClick} className={classes.primary} disabled={disabled}>
      {children}
    </ButtonBase>
  );
}
